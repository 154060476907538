export const BG_PRIMARY = '#fff';
export const BG_SECONDARY = '#7b5f53';
export const BG_SECONDARY_LIGHT = '#dca74f';
export const BG_SECONDARY_DARK = '#6f5347';
export const BG_SECONDARY_TRANSPARENT = 'rgb(123 95 83 / 40%)';
export const BG_NAVIGATION = `#b49c50`;

export const TEXT_PRIMARY_LIGHT = '#cf9c2f';
export const TEXT_PRIMARY = BG_SECONDARY;
export const TEXT_PRIMARY_DARK = BG_SECONDARY_DARK;
export const TEXT_SECONDARY = '#fff';